<template>
  <van-popup v-model="dialogData.isShow" round position="bottom">
    <van-datetime-picker
      class="datetime-picker-reset-bg"
      :class="{ 'year-datetime': dialogData.type === 'year' }"
      :value="currentDate"
      :title="title"
      :type="pickerType"
      :min-date="minDate"
      :max-date="maxDate"
      :formatter="formatter"
      :filter="filter"
      @confirm="onConfirm"
      @cancel="dialogData.isShow = false"
    />
  </van-popup>
</template>
<script>
  import './index.scss'
  import moment from 'moment'
  import { DatetimePicker, Popup } from 'vant'

  export default {
    components: {
      [DatetimePicker.name]: DatetimePicker,
      [Popup.name]: Popup
    },
    model: {
      prop: 'originDate',
      event: 'change'
    },
    props: {
      dialogData: Object,
      originDate: String
    },
    computed: {
      title() {
        return this.dialogData.title ? this.dialogData.title : '选择年月日';
      },
      pickerType() {
        if(this.dialogData.type && this.dialogData.type === 'year') {
          return 'year-month';
        } else {
          return this.dialogData.type ? this.dialogData.type : 'date';
        }
      },
      minDate() {
        return this.dialogData.minDate ? this.dialogData.minDate : new Date(moment().year(moment().year() - 1))
      },
      maxDate() {
        return this.dialogData.maxDate ? this.dialogData.maxDate : new Date(moment().year(moment().year() + 1))
      },
      currentDate() {
        return this.originDate ? new Date(this.originDate.replaceAll('-', '/')) : new Date()
      },
      showNow() {
        return this.dialogData.showNow ? this.dialogData.showNow : false;
      }
    },
    methods: {
      onConfirm(value) {
        let format = {
          'date': 'YYYY-MM-DD',
          'datetime': 'YYYY-MM-DD HH:mm',
          'year-month': 'YYYY-MM',
          'year': 'YYYY'
        }
        if(this.showNow && moment(this.maxDate).year() === moment(value).year()) {
          this.$emit('change', '至今');
        } else {
          if(this.dialogData.type === 'year') {
            this.$emit('change', moment(value).format(format['year']));
          } else {
            this.$emit('change', moment(value).format(format[this.pickerType]));
          }
        }
        this.dialogData.isShow = false;
      },
      formatter(type, val) {
        if(type === 'year') {
          if(this.showNow) {
            if(+val === moment(this.maxDate).year()) {
              return '至今';
            }
          } else {
            return `${val}年`;
          }
        } else if(type === 'month') {
          return `${val}月`;
        } else if(type === 'day') {
          return `${val}日`;
        }
        return val;
      },
      filter(type, options) {
        if(type === 'minute') {
          return options.filter((option) => option % 5 === 0);
        }
        return options;
      }
    }
  }
</script>