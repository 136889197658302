<template>
  <div class="refresh-list-wrap">
    <sub-empty v-if="!list.length && finished && !_loading" :content="content" />
    <van-pull-refresh v-else v-model="_refreshing" @refresh="$emit('refresh')">
      <van-list
        :loading="_loading"
        :finished="finished"
        :error.sync="_error"
        error-text="请求失败，点击重新加载"
        :finished-text="list.length ? '没有更多了' : ''"
        @load="$emit('load')">
        <template v-for="item in list">
          <slot :item="item"></slot>
        </template>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
  import './index.scss'
  import { List, PullRefresh, Cell } from 'vant'
  import subEmpty from '@/components/h5/empty'

  export default {
    components: {
      [List.name]: List,
      [PullRefresh.name]: PullRefresh,
      [Cell.name]: Cell,
      subEmpty
    },
    props: {
      content: {
        type: String
      },
      refreshing: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      finished: {
        type: Boolean,
        default: false
      },
      error: {
        type: Boolean,
        default: false
      },
      list: {
        type: Array,
        default: []
      },
      showType: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      _loading: {
        get() { 
          return this.loading;
        },
        set(value) { 
          this.$emit('changeLoading', value);
        }
      },
      _refreshing: {
        get() { 
          return this.refreshing;
        },
        set(value) { 
          this.$emit('changeRefreshing', value);
        }
      },
      _error: {
        get() { 
          return this.error;
        },
        set(value) { 
          this.$emit('changeError', value);
        }
      }
    }
  }
</script>