<template>
  <div class="empty-wrap">
    <img src="@/assets/images/img_empty.png" />
    <p>{{ content }}</p>
  </div>
</template>

<script>
  import './index.scss'

  export default {
    props: {
      content: {
        type: String,
        default: '暂无数据'
      }
    }
  }
</script>