<template>
  <van-action-sheet class="popup-checkbox-wrap" v-model="dialogData.isShow" @close="onClose" :title="dialogData.title">
    <van-checkbox-group class="checkbox-list" v-model="result">
      <template v-for="(item, index) in dialogData.list">
        <van-cell
          class="checkbox-item"
          clickable
          :key="item.id || index"
          :class="{ 'active': result.includes(item.id || index) }"
          :title="item.name"
          @click="toggle(index)">
          <template #right-icon>
            <van-checkbox :name="item.id || index" shape="square" ref="checkboxes" icon-size="18px"/>
          </template>
        </van-cell>
      </template>
    </van-checkbox-group>
    <div class="footer-btns">
      <van-button class="btn cancel-btn" round @click="onClose">取消</van-button>
      <van-button class="btn confirm-btn" round @click="onConfirm">确定</van-button>
    </div>
  </van-action-sheet>
</template>
<script>
  import './index.scss'
  import { Form, Button, Checkbox, CheckboxGroup, Cell, CellGroup, ActionSheet } from 'vant'
  export default {
    components: {
      [ActionSheet.name]: ActionSheet,
      [Form.name]: Form,
      [Checkbox.name]: Checkbox,
      [CheckboxGroup.name]: CheckboxGroup,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [Button.name]: Button,
    },
    model: {
      prop: 'defaultValue',
      event: 'change'
    },
    props: {
      dialogData: Object,
      defaultValue: {
        type: [Array]
      }
    },
    data() {
      return {
        result: []
      }
    },
    created() {
      this.result = [...this.defaultValue];
    },
    methods: {
      toggle(index) {
        this.$refs.checkboxes[index].toggle();
      },
      onClose() {
        this.$emit('close');
        this.dialogData.isShow = false;
      },
      onConfirm() {
        let resultData = this.dialogData.list.filter((item, index) => {
          return this.result.includes(item.id);
        });
        this.$emit('change', this.result);
        this.$emit('success', resultData);
        this.dialogData.isShow = false;
      }
    }
  }
</script>